
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseLink from "@/components/base/BaseLink.vue";

export default {
  components: {
    BaseHeading,
    BaseLink,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
    link: {
      type: Object,
      required: false,
    },
  },

  methods: {
    bgTheme(block) {
      let theme = block.variant;

      switch (theme) {
        case "dark":
          theme = "bg-gray";
          break;
        default:
          theme = "bg-white";
      }
      return theme;
    },

    btnTheme(block) {
      let theme = block.variant;

      switch (theme) {
        case "dark":
          theme = "btn btn-secondary";
          break;
        default:
          theme = "btn btn-primary";
      }
      return theme;
    },
  },
};
