
import BaseHeading from "../base/BaseHeading.vue";

export default {
  components: {
    BaseHeading,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return this.block?.backgroundImage?.filename ?? "";
    },
  },
};
