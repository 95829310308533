
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseRichText from "@/components/base/BaseRichText/BaseRichText.vue";
import BaseLink from "@/components/base/BaseLink.vue";

export default {
  components: {
    BaseHeading,
    BaseRichText,
    BaseLink,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
};
