
import BaseMenuItem from "./BaseMenuItem.vue";
import BaseLink from "./BaseLink.vue";

export default {
  name: "BaseMenu",
  components: {
    BaseMenuItem,
    BaseLink,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
