
import BaseIcon from "@/components/base/BaseIcon.vue";

export default {
  components: {
    BaseIcon,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    show_arrow: {
      type: Boolean,
      default: false,
    },
  },
};
