
import BaseRichText from "./base/BaseRichText/BaseRichText.vue";
const __sfc_main = {};
__sfc_main.props = {
  blok: Object
};
__sfc_main.setup = (__props, __ctx) => {
  return {};
};
__sfc_main.components = Object.assign({
  BaseRichText
}, __sfc_main.components);
export default __sfc_main;
