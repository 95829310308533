
import BaseLink from "./BaseLink.vue";

export default {
  components: {
    BaseLink,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    theme: {
      type: String,
      default: "primary",
    },
    link: {
      type: Object,
      required: false,
    },
  },
};
