
import BaseIcon from "@/components/base/BaseIcon.vue";
import BaseMenuItem from "@/components/base/BaseMenuItem.vue";
import BaseLink from "@/components/base/BaseLink.vue";
import { mapState } from "vuex";
import { Portal } from "portal-vue";

export default {
  components: {
    BaseMenuItem,
    BaseIcon,
    BaseLink,
    Portal,
  },
  props: {
    logo: { type: Object },
    nav: {
      type: Array,
    },
  },
  computed: {
    ...mapState("global", ["isMobileMenuOpen", "pageHasModalOpen"]),
    isHomePage() {
      return this.$route.fullPath === "/" || this.$route.fullPath === "/home";
    },
  },
  watch: {
    $route(to, from) {
      this.closeMenu();
    },
  },
  methods: {
    async toggleMobileMenu() {
      await this.$store.commit(
        "global/isMobileMenuOpen",
        !this.isMobileMenuOpen
      );
      await this.$nextTick();
      await this.$nextTick();

      this.$refs.closeButtonRef?.focus();
    },
    async closeMenu() {
      await this.$store.commit("global/isMobileMenuOpen", false);
      await this.$nextTick();
      await this.$nextTick();

      this.$refs.openButtonRef?.focus();
    },
  },
};
