
import { mapState } from "vuex";
import SiteHeader from "@/components/SiteHeader.vue";
import { PortalTarget } from "portal-vue";
import SiteFooter from "@/components/SiteFooter";

export default {
  components: {
    SiteHeader,
    SiteFooter,
    PortalTarget,
  },
  computed: {
    ...mapState("global", [
      "pageHasModalOpen",
      "isMobileMenuOpen",
      "headerNavigation",
      "headerLogo",
      "footerLogo",
      "footerNavigation",
    ]),
    hasOpenMenu() {
      return this.pageHasModalOpen || this.isMobileMenuOpen;
    },
  },
};
