
import { useStoryblokApi } from "@storyblok/nuxt-2";
import BaseHeading from "@/components/base/BaseHeading.vue";
export default {
  components: {
    BaseHeading,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      loading: true,
    };
  },
  async fetch() {
    const storyblokApi = useStoryblokApi();

    await this.getEvents(storyblokApi);
  },
  methods: {
    async getEvents(api) {
      const eventSlugs = this.block?.events
        .map((event) => {
          return event.event.cached_url;
        })
        .join(",");

      // write a function that takes in an array of slugs and returns an array of stories and ensures they load on the page

      await api
        .get(`cdn/stories`, {
          version: this.version,
          by_slugs: eventSlugs,
        })
        .then((res) =>
          res.data.stories.map((event) => {
            this.events.push({
              id: event.uuid,
              title: event.name,
              date: event.content?.date,
              time: event.content?.time,
              image: event.content?.image.filename,
              link: event.full_slug,
            });
          })
        );

      this.loading = false;
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
