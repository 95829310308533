
import { renderRichText } from "@storyblok/nuxt-2";
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    document() {
      return renderRichText(this.content);
    },
  },
};
