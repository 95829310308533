
import BaseIcon from "./base/BaseIcon.vue";
import BaseLink from "@/components/base/BaseLink.vue";

export default {
  components: {
    BaseIcon,
    BaseLink,
  },
  props: {
    nav: {
      type: Array,
      required: true,
    },
    logo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentYear() {
      const date = new Date();

      return date.getFullYear();
    },
  },
};
