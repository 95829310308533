
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseLink from "@/components/base/BaseLink.vue";

export default {
  components: {
    BaseHeading,
    BaseButton,
    BaseLink,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    overlayColor(num) {
      let color;
      switch (num) {
        case 0:
          color = "bg-secondary";
          break;
        case 1:
          color = "bg-accent";
          break;
      }

      return color;
    },
  },
};
