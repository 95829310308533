export default {
  AddressIcon: () => import("~/assets/icons/address-icon.svg?inline"),
  PhoneIcon: () => import("~/assets/icons/phone-icon.svg?inline"),
  MenuIcon: () => import("~/assets/icons/menu-icon.svg?inline"),
  CloseIcon: () => import("~/assets/icons/close-icon.svg?inline"),
  CheckIcon: () => import("~/assets/icons/check-icon.svg?inline"),
  QuoteIcon: () => import("~/assets/icons/quote-icon.svg?inline"),
  FacebookIcon: () => import("~/assets/icons/facebook-icon.svg?inline"),
  HomeplateIcon: () => import("~/assets/icons/homeplate-icon.svg?inline"),
  ChatIcon: () => import("~/assets/icons/chat-icon.svg?inline"),
  ArrowRight: () => import("~/assets/icons/arrow-right.svg?inline"),
};
