
import BaseRichText from "@/components/base/BaseRichText/BaseRichText.vue";
import BaseIcon from "@/components/base/BaseIcon.vue";

export default {
  components: {
    BaseRichText,
    BaseIcon,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      // const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
