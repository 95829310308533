
import BaseHeading from "@/components/base/BaseHeading.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon.vue";
import BaseLink from "../base/BaseLink.vue";

export default {
  components: {
    BaseHeading,
    BaseButton,
    BaseIcon,
    BaseLink,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    backgroundContent() {
      return this.block.backgroundContent[0];
    },
    hasVideo() {
      return this.block.backgroundContent[0].image ? true : false;
    },
  },
  methods: {
    bgColor(num) {
      let color;
      switch (num) {
        case 0:
          color = "bg-primary";
          break;
        case 1:
          color = "bg-secondary";
          break;
        case 2:
          color = "bg-accent";
          break;
        case 3:
          color = "bg-primary";
          break;
        case 4:
          color = "bg-secondary";
          break;
      }

      return color;
    },
  },
};
