
import BaseHeading from "@/components//base/BaseHeading.vue";
import BaseRichText from "@/components/base/BaseRichText/BaseRichText.vue";

export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  components: { BaseHeading, BaseRichText },
};
