
  import BaseHeading from "@/components/base/BaseHeading.vue";
  export default {
    components: {
      BaseHeading,
    },
    props: {
      block: {
        type: Object,
        required: true,
      },
    }
  }
